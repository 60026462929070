//import $ from "jquery";


class FinancialResults {

	CM: CashManagement = new CashManagement();
	GM: GlobalManagement = new GlobalManagement();
	FM: FinancialManagement = new FinancialManagement();
	IM: InvestmentManagement = new InvestmentManagement();
	OM: OperatingManagement = new OperatingManagement();
}

class CashManagement {
	operatingCashFlow: Item = new Item(new ItemElements);
	ChangeOperatingCashFlow: Item  = new Item(new ItemElements);
	investingCashFlow: Item = new Item(new ItemElements);
	ChangeInvestingCashFlow: Item = new Item(new ItemElements);
	financingCashFlow: Item = new Item(new ItemElements);
	ChangeFinancingCashFlow: Item = new Item(new ItemElements);
	CAPEX: Item = new Item(new ItemElements);
	changeInCAPEX: Item = new Item(new ItemElements);
	adquisitions: Item = new Item(new ItemElements);
	changeInAdquisitions: Item = new Item(new ItemElements);
	securitiesNet: Item = new Item(new ItemElements);
	changeInSecurities: Item = new Item(new ItemElements);
	
	investing: Item = new Item(new ItemElements); 
	changeInInvesting: Item = new Item(new ItemElements);
	FCF: Item = new Item(new ItemElements);
	changeInFCF: Item = new Item(new ItemElements);
	dividends: Item = new Item(new ItemElements);
	changeInDividends: Item = new Item(new ItemElements);
	stockRepurchase: Item = new Item(new ItemElements);
	changeInStockRepurchase: Item = new Item(new ItemElements);
	debtRepayment: Item = new Item(new ItemElements);
	changeInDebtRepayment: Item = new Item(new ItemElements);

	FCFYield: number = null;  
	DividendYield: number = null;		
	RepurchasesYield: number = null;		
	SecuritiesSaleYield: number = null; 
	DebtBuyingYield: number = null; 
	CAPEXYield: number = null; 
	AdquisitionsYield: number = null; 
	InvestingYield: number = null;

}

class FinancialManagement {
	netInterestEarningsAfterTaxes: Item = new Item(new ItemElements);
	longTermDebt: Item = new Item(new ItemElements);
	netDebt: Item = new Item(new ItemElements);
	equity: Item = new Item(new ItemElements);
	debtToCapitalRatio: Item = new Item(new ItemElements);
	netDebtToNetCapitalRatio: Item = new Item(new ItemElements);
	spread: Item = new Item(new ItemElements);
	netFinancialLeverage: Item = new Item(new ItemElements);
	financialLeverage: Item = new Item(new ItemElements);
	currentRatio: Item = new Item(new ItemElements);
	quickRatio: Item = new Item(new ItemElements);
	cashRatio: Item = new Item(new ItemElements);
	interestCoverage_operatingIncomeVSinterestexpense: Item = new Item(new ItemElements);
	interestCoverage_operatingCashFlowVSinterestexpense: Item = new Item(new ItemElements);
	
}

class GlobalManagement {
	operatingROA: Item = new Item(new ItemElements);
	salesOverAssets: Item = new Item(new ItemElements);
	
	financialLeverageGain: Item = new Item(new ItemElements);
	ROE: Item = new Item(new ItemElements);
	returnOnTangibleEquity: Item = new Item(new ItemElements);
	payOut: Item = new Item(new ItemElements);
	dividendYield: Item = new Item(new ItemElements);
	FCFOverEquity: Item = new Item(new ItemElements);
	FCFPerShare: Item = new Item(new ItemElements);
	earningsPerShare: Item = new Item(new ItemElements);
	operatingIncomePerShare: Item = new Item(new ItemElements);
	growthRate: Item = new Item(new ItemElements);
	
	salesGrowthRate: Item = new Item(new ItemElements);
	NOPATMargin: Item = new Item(new ItemElements);
	beginningNetOperatingWCOverSales: Item = new Item(new ItemElements);
	beginningNetOperatingLTAssetsOverSales: Item = new Item(new ItemElements);
	beginningNetDebt2CapitalRatio: Item = new Item(new ItemElements);
	afterTaxCostOfDebt: Item = new Item(new ItemElements);
}

class InvestmentManagement {
	LTAssets: Item = new Item(new ItemElements);
	accountsPayable: Item = new Item(new ItemElements);
	accountsPayableSales: Item = new Item(new ItemElements);
	accountsReceivable: Item = new Item(new ItemElements);
	accountsReceivableGrowth: Item = new Item(new ItemElements);
	accountsReceivableOverSales: Item = new Item(new ItemElements);
	cashAndMarketableSecurities: Item = new Item(new ItemElements);
	cashOverNetAssets: Item = new Item(new ItemElements);
	cashOverReceivables: Item = new Item(new ItemElements);
	currentAssets: Item = new Item(new ItemElements);
	currentLiabilities: Item = new Item(new ItemElements);
	daysInventory: Item = new Item(new ItemElements);
	daysPayables: Item = new Item(new ItemElements);
	daysReceivables: Item = new Item(new ItemElements);
	goodwillAndIntangibles: Item = new Item(new ItemElements);
	inventory: Item = new Item(new ItemElements);
	inventoryGrowth: Item = new Item(new ItemElements);
	inventorySales: Item = new Item(new ItemElements);
	longTermDebt: Item = new Item(new ItemElements);
	netAssets: Item = new Item(new ItemElements);
	netLTAssets: Item = new Item(new ItemElements);
	netWorkingCapital: Item = new Item(new ItemElements);
	nonInterestBearingLTLiabilities: Item = new Item(new ItemElements);
	salesOverNetAssets: Item = new Item(new ItemElements);
	salesOverNetLTAssets: Item = new Item(new ItemElements);
	salesOverWorkingCapital: Item = new Item(new ItemElements);
	shortTermDebtAndCurrentPortionOfLongTermDebt: Item = new Item(new ItemElements);
}

class OperatingManagement {
	revenue: Item = new Item(new ItemElements);
	salesGrowth: Item = new Item(new ItemElements);
	COGS: Item = new Item(new ItemElements);
	operatingIncome: Item = new Item(new ItemElements);
	grossMargin: Item = new Item(new ItemElements);
	SGA: Item = new Item(new ItemElements);
	SGAOverSales: Item = new Item(new ItemElements);
	NOPAT: Item = new Item(new ItemElements);
	NOPATGrowth: Item = new Item(new ItemElements);
	NOPATMargin: Item = new Item(new ItemElements);
	operatingMargin: Item = new Item(new ItemElements);
	provisionForIncomeTaxes: Item = new Item(new ItemElements);
	taxRate: Item = new Item(new ItemElements); 
	interestExpense: Item = new Item(new ItemElements);
	netIncome: Item = new Item(new ItemElements);
	incomeOverRevenue: Item = new Item(new ItemElements);
	operatingCashFlow: Item = new Item(new ItemElements);
	operatingCashFlowOverIncome: Item = new Item(new ItemElements);


}


class Item {
	constructor(item: ItemElements){
		this.item = item;
	}

	
	item: ItemElements;
	

	length() {
		
		return 5;
	}

	

}

class ItemElements {

	constructor(){
		
	}

	item1: number = 0; 
	item2: number = 0; 
	item3: number = 0; 
	item4: number = 0; 
	item5: number = 0; 

	
}

function lastYear(json: Item): string {
	var ly: string = "";
		for (var keyFR in json) {
		  if (json.hasOwnProperty(keyFR)) {
		  	ly = keyFR;
		  }
		}
		return ly;
}


//import * as numeral from '../../node_modules/numeral/numeral';



$(document).ready(function(){
	
  $("#analizar").click(function(){

  	var company = (<HTMLInputElement>document.getElementById("company")).value;
  	console.log(company);

  	$.get("https://api.adarga.org/analysis?com=" + company, function(data){
  	

  	/***********************
	
			Construct the HTML code needed

    	*/

  	


  	/**

		MOBILE

  	*/

  	var VM = <HTMLElement>document.getElementById("VM");
  	VM.style.visibility = "visible";

  	var CMM = <HTMLElement>document.getElementById("CMM");
  	CMM.style.visibility = "visible";

  	var GMM = <HTMLElement>document.getElementById("GMM");
  	GMM.style.visibility = "visible";

  	var OMM = <HTMLElement>document.getElementById("OMM");
  	OMM.style.visibility = "visible";

  	var IMM = <HTMLElement>document.getElementById("IMM");
  	IMM.style.visibility = "visible";

  	var FMM = <HTMLElement>document.getElementById("FMM");
  	FMM.style.visibility = "visible";

  	
  	

  	/**

		DESKTOP

  	*/

  	var V = <HTMLElement>document.getElementById("V");
  	V.style.visibility = "visible";

  	var bodyV = <HTMLElement>document.getElementById("tbodyV");
  	var tableV = <HTMLElement>document.getElementById("tableV");
  	tableV.removeChild(bodyV);

  	var CM = <HTMLElement>document.getElementById("CM");
  	CM.style.visibility = "visible";

  	var bodyCM = <HTMLElement>document.getElementById("tbodyCM");
  	var tableCM = <HTMLElement>document.getElementById("tableCM");
  	tableCM.removeChild(bodyCM);

  	var GM = <HTMLElement>document.getElementById("GM");
  	GM.style.visibility = "visible";

  	var bodyGM = <HTMLElement>document.getElementById("tbodyGM");
  	var tableGM = <HTMLElement>document.getElementById("tableGM");
  	tableGM.removeChild(bodyGM);

  	var OM = <HTMLElement>document.getElementById("OM");
  	OM.style.visibility = "visible";

  	var bodyOM = <HTMLElement>document.getElementById("tbodyOM");
  	var tableOM = <HTMLElement>document.getElementById("tableOM");
  	tableOM.removeChild(bodyOM);

  	var IM = <HTMLElement>document.getElementById("IM");
  	IM.style.visibility = "visible";

  	var bodyIM = <HTMLElement>document.getElementById("tbodyIM");
  	var tableIM = <HTMLElement>document.getElementById("tableIM");
  	tableIM.removeChild(bodyIM);
  	
  	var FM = <HTMLElement>document.getElementById("FM");
  	FM.style.visibility = "visible";

  	var bodyFM = <HTMLElement>document.getElementById("tbodyFM");
  	var tableFM = <HTMLElement>document.getElementById("tableFM");
  	tableFM.removeChild(bodyFM);

  	




  	

    
    	
    	let fr: FinancialResults = JSON.parse(data);
    	console.log(fr);
    	
    	//require('typescript-require');
		var numeral = require('../../node_modules/numeral/numeral');



		 /***********************
	
			Valuation

    	*/

    	var itemNameV = ["FCF Yield",
	    		"Dividend Yield",
	    		"Repurchases Yield",
	    		"Securities Sale Yield",
	    		"Debt Issuance Yield",
	    		"CAPEX Yield",
	    		"Adquisitions Yield",
	    		"Investing Yield",
	    	];

	    var itemNumbersV = [fr.CM.FCFYield,
	    		fr.CM.DividendYield,
	    		fr.CM.RepurchasesYield,
	    		fr.CM.SecuritiesSaleYield,
	    		fr.CM.DebtBuyingYield,
	    		fr.CM.CAPEXYield,
	    		fr.CM.AdquisitionsYield,
	    		fr.CM.InvestingYield,
	    		
	    	];

	    var bodyV = <HTMLElement>document.createElement("tbody");
	    bodyV.setAttribute("id", "tbodyV");
	    var dummy7  = <HTMLElement>document.getElementById("tableV");
	    dummy7.appendChild(bodyV);
	    		
		var col_1V = <HTMLElement>document.getElementById("col_1V");
		col_1V.innerHTML = "Yield";
		


	    for (var j=0; j<itemNumbersV.length; j++) {
	    	console.log(itemNameV[j]);
	    	console.log(itemNumbersV[j]);
	    	var th = document.createElement("th").appendChild(document.createTextNode(itemNameV[j])); 
		    var td = document.createElement("td");
		    var tr = document.createElement("tr");
		    td.appendChild(th);
		    tr.appendChild(td);

		    var convertido: string = itemNumbersV[j].toLocaleString(undefined,{style: 'percent', minimumFractionDigits:1});
		    var thi = document.createElement("th").appendChild(document.createTextNode(convertido));
		    var tdi = document.createElement("td");
		    tdi.id = itemNameV[j];
		    tdi.appendChild(thi);
			tr.appendChild(tdi);

		    var dummy8 = <HTMLElement>document.getElementById("tbodyV");
		    dummy8.appendChild(tr);
	    }



		 /**

			MOBILE Valuation

  		*/


	    for (var j=0; j<itemNumbersV.length; j++) {

	    	// Creating table and the header
	    	var id = <HTMLElement>document.createElement("id");
	    	id.setAttribute("class", "table-responsive table-striped w-75 mx-auto");
	    	
	    	var table = <HTMLElement>document.createElement("table");
	    	table.setAttribute("class", "table table-hover table-striped w-75 mx-auto");
	    	
	    	var thead = <HTMLElement>document.createElement("thead");
	    	thead.setAttribute("class", "thead-dark");
	    	var tr = document.createElement("tr");
	    	tr.setAttribute("scope", "col");
	    	var itenName = document.createElement("th").appendChild(document.createTextNode("")); 
	    	var tHeader = <HTMLTableHeaderCellElement>document.createElement("th");
	    	tHeader.setAttribute("scope", "col");
	    	tHeader.appendChild(itenName);
	    	var noChar = document.createElement("th").appendChild(document.createTextNode("          ")); 
	    	var tHeader2 = <HTMLTableHeaderCellElement>document.createElement("th");
	    	tHeader2.setAttribute("scope", "col");
	    	tHeader2.appendChild(noChar);

	    	tr.appendChild(tHeader);
	    	tr.appendChild(tHeader2);
	    	thead.appendChild(tr);
	    	table.appendChild(thead);
	    	id.appendChild(table);


	    	// Creating rows of the table per year

	    	var tbody = <HTMLElement>document.createElement("tbody");	    

		    for (var j=0; j<itemNumbersV.length; j++) {
	    	
	    	var th = document.createElement("th").appendChild(document.createTextNode(itemNameV[j])); 
		    var td = document.createElement("td");
		    var tr = document.createElement("tr");
		    td.appendChild(th);
		    tr.appendChild(td);

		    var convertido: string = itemNumbersV[j].toLocaleString(undefined,{style: 'percent', minimumFractionDigits:1});
		    var thi = document.createElement("th").appendChild(document.createTextNode(convertido));
		    var tdi = document.createElement("td");
		    tdi.id = itemNameV[j];
		    tdi.appendChild(thi);
			tr.appendChild(tdi);
			tbody.appendChild(tr);

		   
	    }
		    
		    table.appendChild(tbody);
		    var dummy10 = <HTMLElement>document.getElementById("VM");
		    dummy10.appendChild(table);

		}




	     /***********************
	
			Cash Management

    	*/

	    var itemNameCM = [
	    		"FCF",
	    		"Change In FCF",
	    		"Operating Cash Flow",
	    		"Change Operating Cash Flow",
	    		"Investing Cash Flow",
	    		"Change Investing Cash Flow",
	    		"Financing Cash Flow",
	    		"Change Financing Cash Flow",
	    		"CAPEX",
	    		"Change In CAPEX",
	    		"Adquisitions",
	    		"Change In Adquisitions",
	    		"CAPEX + Adquisitions",
	    		"Change In CAPEX + Adquisitions",
	    		"Securities Sale Net",
	    		"Change In Securities",
	    		"Dividends",
	    		"Change In Dividends",
	    		"Stock Repurchase Net",
	    		"Change In Stock Repurchase",
	    		"Debt Issuance Net",
	    		"Change In Debt Repayment",
	    	];

	    var itemNumbersCM = [
	    		fr.CM.FCF,
	    		fr.CM.changeInFCF,
	    		fr.CM.operatingCashFlow,
	    		fr.CM.ChangeOperatingCashFlow,
	    		fr.CM.investingCashFlow,
	    		fr.CM.ChangeInvestingCashFlow,
	    		fr.CM.financingCashFlow,
	    		fr.CM.ChangeFinancingCashFlow,
	    		fr.CM.CAPEX,
	    		fr.CM.changeInCAPEX,
	    		fr.CM.adquisitions,
	    		fr.CM.changeInAdquisitions,
	    		fr.CM.investing,
	    		fr.CM.changeInInvesting,
	    		fr.CM.securitiesNet,
	    		fr.CM.changeInSecurities,
	    		fr.CM.dividends,
	    		fr.CM.changeInDividends,
	    		fr.CM.stockRepurchase,
	    		fr.CM.changeInStockRepurchase,
	    		fr.CM.debtRepayment,
	    		fr.CM.changeInDebtRepayment,
	    	];

	    var bodyCMM = <HTMLElement>document.createElement("tbody");
	    bodyCM.setAttribute("id", "tbodyCM");
	    var dummy7  = <HTMLElement>document.getElementById("tableCM");
	    dummy7.appendChild(bodyCM);
	    var lastyear = lastYear(fr.CM.operatingCashFlow);
		
		var col_1CM = <HTMLElement>document.getElementById("col_1CM");
		col_1CM.innerHTML = String(lastyear);
		var col_2CM = <HTMLElement>document.getElementById("col_2CM");
		col_2CM.innerHTML = String(parseInt(lastyear) -1);
		var col_3CM = <HTMLElement>document.getElementById("col_3CM");
		col_3CM.innerHTML = String(parseInt(lastyear) -2);
		var col_4CM = <HTMLElement>document.getElementById("col_4CM");
		col_4CM.innerHTML = String(parseInt(lastyear) -3);
		var col_5CM = <HTMLElement>document.getElementById("col_5CM");
		col_5CM.innerHTML = String(parseInt(lastyear) -4);


	    for (var j=0; j<itemNumbersCM.length; j++) {
	    	
	    	var th = document.createElement("th").appendChild(document.createTextNode(itemNameCM[j])); 
		    var td = document.createElement("td");
		    var tr = document.createElement("tr");
		    td.appendChild(th);
		    tr.appendChild(td);

		    
		    

		    for (var i=0; i<5;i++) {
		    	var convertido: string;
		  		var itemPaint4: any = itemNumbersCM[j];
		  		itemPaint = itemPaint4[parseInt(lastyear) - i];
		  		if ((typeof itemPaint) == 'number') {
			    	
			    	convertido = itemPaint.toLocaleString(undefined,{style: 'percent', minimumFractionDigits:1});
		  			
		  			if (itemNameCM[j] == "CAPEX") { convertido = numeral(itemPaint).format('0,0'); } 
		  			if (itemNameCM[j] == "Securities Sale Net") { convertido = numeral(itemPaint).format('0,0'); } 
		  			if (itemNameCM[j] == "Adquisitions") { convertido = numeral(itemPaint).format('0,0'); } 
		  			if (itemNameCM[j] == "CAPEX + Adquisitions") { convertido = numeral(itemPaint).format('0,0'); } 
		  			if (itemNameCM[j] == "Investing (CAPEX + Adquisitions)") { convertido = numeral(itemPaint).format('0,0'); }
		  			if (itemNameCM[j] == "Dividends") { convertido = numeral(itemPaint).format('0,0'); } 
		  			if (itemNameCM[j] == "Stock Repurchase Net") { convertido = numeral(itemPaint).format('0,0'); } 
		  			if (itemNameCM[j] == "Debt Issuance Net") { convertido = numeral(itemPaint).format('0,0'); } 


			    } else {
			    	convertido = numeral(Number(itemPaint)).format('0,0');
			    }

			    if (itemPaint > 1000000) { convertido = "Inf"; }

		    	var thi = document.createElement("th").appendChild(document.createTextNode(convertido));
		    	var tdi = document.createElement("td");
		    	tdi.id = itemNameCM[j] + i;
		    	tdi.appendChild(thi);
				tr.appendChild(tdi);
				
		    }
		    var dummy8 = <HTMLElement>document.getElementById("tbodyCM");
		    dummy8.appendChild(tr);
	    }



		 /**

			MOBILE Cash Management

  		*/


	    for (var j=0; j<itemNumbersCM.length; j++) {

	    	// Creating table and the header
	    	var id = <HTMLElement>document.createElement("id");
	    	id.setAttribute("class", "table-responsive table-striped w-75 mx-auto");
	    	
	    	var table = <HTMLElement>document.createElement("table");
	    	table.setAttribute("class", "table table-hover table-striped w-75 mx-auto");
	    	
	    	var thead = <HTMLElement>document.createElement("thead");
	    	thead.setAttribute("class", "thead-dark");
	    	var tr = document.createElement("tr");
	    	tr.setAttribute("scope", "col");
	    	var itenName = document.createElement("th").appendChild(document.createTextNode(itemNameCM[j])); 
	    	var tHeader = <HTMLTableHeaderCellElement>document.createElement("th");
	    	tHeader.setAttribute("scope", "col");
	    	tHeader.appendChild(itenName);
	    	var noChar = document.createElement("th").appendChild(document.createTextNode("          ")); 
	    	var tHeader2 = <HTMLTableHeaderCellElement>document.createElement("th");
	    	tHeader2.setAttribute("scope", "col");
	    	tHeader2.appendChild(noChar);

	    	tr.appendChild(tHeader);
	    	tr.appendChild(tHeader2);
	    	thead.appendChild(tr);
	    	table.appendChild(thead);
	    	id.appendChild(table);


	    	// Creating rows of the table per year

	    	var tbody = <HTMLElement>document.createElement("tbody");	    

		    for (var i=0; i<5;i++) {

		    	var convertido: string;
		  		var itemPaint2: any = itemNumbersCM[j];
		  		var itemPaint = itemPaint2[parseInt(lastyear) - i];

		  		if ((typeof itemPaint) == 'number') {

		  			convertido = itemPaint.toLocaleString(undefined,{style: 'percent', minimumFractionDigits:1});
		  			if (itemNameCM[j] == "CAPEX") { convertido = numeral(itemPaint).format('0,0'); } 
		  			if (itemNameCM[j] == "Adquisitions") { convertido = numeral(itemPaint).format('0,0'); } 
		  			if (itemNameCM[j] == "CAPEX + Adquisitions") { convertido = numeral(itemPaint).format('0,0'); } 
		  			if (itemNameCM[j] == "Securities Sale Net") { convertido = numeral(itemPaint).format('0,0'); } 
		  			if (itemNameCM[j] == "Investing (CAPEX + Adquisitions)") { convertido = numeral(itemPaint).format('0,0'); }
		  			if (itemNameCM[j] == "Dividends") { convertido = numeral(itemPaint).format('0,0'); } 
		  			if (itemNameCM[j] == "Stock Repurchase Net") { convertido = numeral(itemPaint).format('0,0'); } 
		  			if (itemNameCM[j] == "Debt Issuance Net") { convertido = numeral(itemPaint).format('0,0'); } 
		  			
			    } else {
			    	convertido = numeral(Number(itemPaint)).format('0,0');
			    }

			    if (itemPaint > 1000000) { convertido = "Inf"; }

			    

			    var yeari = parseInt(lastyear) - i;
			    var yeariS = yeari.toString();
			    var year = document.createElement("td").appendChild(document.createTextNode(yeariS));
			    var tdYear = document.createElement("td");
			    tdYear.appendChild(year);

		    	var value = document.createElement("td").appendChild(document.createTextNode(convertido));
		    	var tdValue = document.createElement("td");
			    tdValue.appendChild(value);

		    	var tr = document.createElement("tr");
		    	tr.appendChild(tdYear);
		    	tr.appendChild(tdValue);
				tbody.appendChild(tr);
				
				
				
		    }
		    
		    table.appendChild(tbody);
		    var dummy10 = <HTMLElement>document.getElementById("CMM");
		    dummy10.appendChild(table);

		}






	    /***********************
	
			Global Management

    	*/

	    var itemNameGM = ["Operating ROA",
	    		"Sales Over Assets",
	    		"Financial Leverage Gain",
	    		"ROE",
	    		"Return On Tangible Equity",
	    		"Pay Out",
	    		"Dividend Yield",
	    		"FCF Over Equity",
	    		"FCF Per Share",
	    		"Earnings Per Share",
	    		"Operating Income Per Share",
	    		"Growth Rate",
	    		"Sales Growth Rate",
	    		"NOPAT Margin",
	    		"Beginning Net Operating WC Over Sales",
	    		"Beginning Net Operating LT Assets Over Sales",
	    		"Beginning Net Debt 2 Capital Ratio",
	    		"After TaxCost Of Debt",
	    	];

	    var itemNumbersGM = [fr.GM.operatingROA,
	    		fr.GM.salesOverAssets,
	    		fr.GM.financialLeverageGain,
	    		fr.GM.ROE,
	    		fr.GM.returnOnTangibleEquity,
	    		fr.GM.payOut,
	    		fr.GM.dividendYield,
	    		fr.GM.FCFOverEquity,
	    		fr.GM.FCFPerShare,
	    		fr.GM.earningsPerShare,
	    		fr.GM.operatingIncomePerShare,
	    		fr.GM.growthRate,
	    		fr.GM.salesGrowthRate,
	    		fr.GM.NOPATMargin,
	    		fr.GM.beginningNetOperatingWCOverSales,
	    		fr.GM.beginningNetOperatingLTAssetsOverSales,
	    		fr.GM.beginningNetDebt2CapitalRatio,
	    		fr.GM.afterTaxCostOfDebt,
	    	];

	    var bodyGM = <HTMLElement>document.createElement("tbody");
	    bodyGM.setAttribute("id", "tbodyGM");
	    var dummy9 = <HTMLElement>document.getElementById("tableGM");
	    dummy9.appendChild(bodyGM);
	    var lastyear = lastYear(fr.CM.operatingCashFlow);
		
		var col_1GM = <HTMLElement>document.getElementById("col_1GM");
		col_1GM.innerHTML = String(lastyear);
		var col_2GM = <HTMLElement>document.getElementById("col_2GM");
		col_2GM.innerHTML = String(parseInt(lastyear) -1);
		var col_3GM = <HTMLElement>document.getElementById("col_3GM");
		col_3GM.innerHTML = String(parseInt(lastyear) -2);
		var col_4GM = <HTMLElement>document.getElementById("col_4GM");
		col_4GM.innerHTML = String(parseInt(lastyear) -3);
		var col_5GM = <HTMLElement>document.getElementById("col_5GM");
		col_5GM.innerHTML = String(parseInt(lastyear) -4);


	    for (var j=0; j<itemNumbersGM.length; j++) {
	    	
	    	var th = document.createElement("th").appendChild(document.createTextNode(itemNameGM[j])); 
		    var td = document.createElement("td");
		    var tr = document.createElement("tr");
		    td.appendChild(th);
		    tr.appendChild(td);

		    
		    

		    for (var i=0; i<5;i++) {
		    	var convertido: string;
		  		var itemPaint5: any = itemNumbersGM[j];
		  		itemPaint = itemPaint5[parseInt(lastyear) - i];
		  		if ((typeof itemPaint) == 'number') {
			    	convertido = itemPaint.toLocaleString(undefined,{style: 'percent', minimumFractionDigits:1});
			    	if (itemNameGM[j] == "FCF Per Share") { convertido = numeral(itemPaint).format('0,0.00'); } 
			    	if (itemNameGM[j] == "Earnings Per Share") { convertido = numeral(itemPaint).format('0,0.00'); } 
			    	if (itemNameGM[j] == "Operating Income Per Share") { convertido = numeral(itemPaint).format('0,0.00'); } 
			    } else {
			    	convertido = numeral(Number(itemPaint)).format('0,0');
			    }

			    if (itemPaint > 1000000) { convertido = "Inf"; }


		    	var thi = document.createElement("th").appendChild(document.createTextNode(convertido));
		    	var tdi = document.createElement("td");
		    	tdi.id = itemNameGM[j] + i;
		    	tdi.appendChild(thi);
				tr.appendChild(tdi);
				
		    }
		    var dummy10 = <HTMLElement>document.getElementById("tbodyGM");
		    dummy10.appendChild(tr);
	    }


	     /**

			MOBILE Global Management

  		*/


	    for (var j=0; j<itemNumbersGM.length; j++) {

	    	// Creating table and the header
	    	var id = <HTMLElement>document.createElement("id");
	    	id.setAttribute("class", "table-responsive table-striped w-75 mx-auto");
	    	
	    	var table = <HTMLElement>document.createElement("table");
	    	table.setAttribute("class", "table table-hover table-striped w-75 mx-auto");
	    	
	    	var thead = <HTMLElement>document.createElement("thead");
	    	thead.setAttribute("class", "thead-dark");
	    	var tr = document.createElement("tr");
	    	tr.setAttribute("scope", "col");
	    	var itenName = document.createElement("th").appendChild(document.createTextNode(itemNameGM[j])); 
	    	var tHeader = <HTMLTableHeaderCellElement>document.createElement("th");
	    	tHeader.setAttribute("scope", "col");
	    	tHeader.appendChild(itenName);
	    	var noChar = document.createElement("th").appendChild(document.createTextNode("          ")); 
	    	var tHeader2 = <HTMLTableHeaderCellElement>document.createElement("th");
	    	tHeader2.setAttribute("scope", "col");
	    	tHeader2.appendChild(noChar);

	    	tr.appendChild(tHeader);
	    	tr.appendChild(tHeader2);
	    	thead.appendChild(tr);
	    	table.appendChild(thead);
	    	id.appendChild(table);


	    	// Creating rows of the table per year

	    	var tbody = <HTMLElement>document.createElement("tbody");	    

		    for (var i=0; i<5;i++) {

		    	var convertido: string;
		  		var itemPaint2: any = itemNumbersGM[j];
		  		var itemPaint = itemPaint2[parseInt(lastyear) - i];

		  		if ((typeof itemPaint) == 'number') {

		  			convertido = itemPaint.toLocaleString(undefined,{style: 'percent', minimumFractionDigits:1});
		  			if (itemNameGM[j] == "FCF Per Share") { convertido = numeral(itemPaint).format('0,0.00'); } 
			    	if (itemNameGM[j] == "Earnings Per Share") { convertido = numeral(itemPaint).format('0,0.00'); } 
			    	if (itemNameGM[j] == "Operating Income Per Share") { convertido = numeral(itemPaint).format('0,0.00'); } 
		  			
			    } else {
			    	convertido = numeral(Number(itemPaint)).format('0,0');
			    }

			    if (itemPaint > 1000000) { convertido = "Inf"; }

			    

			    var yeari = parseInt(lastyear) - i;
			    var yeariS = yeari.toString();
			    var year = document.createElement("td").appendChild(document.createTextNode(yeariS));
			    var tdYear = document.createElement("td");
			    tdYear.appendChild(year);

		    	var value = document.createElement("td").appendChild(document.createTextNode(convertido));
		    	var tdValue = document.createElement("td");
			    tdValue.appendChild(value);

		    	var tr = document.createElement("tr");
		    	tr.appendChild(tdYear);
		    	tr.appendChild(tdValue);
				tbody.appendChild(tr);
				
				
				
		    }
		    
		    table.appendChild(tbody);
		    var dummy10 = <HTMLElement>document.getElementById("GMM");
		    dummy10.appendChild(table);

		}


    	
    	/***********************
	
			Operating Management

    	*/

	    var itemNameOM = ["Revenue",
	    		"Sales Growth",
	    		"COGs",
	    		"Operating Income",
	    		"Gross Margin",
	    		"SGA",
	    		"SGA Growth",
	    		"NOPAT",
	    		"NOPAT Growth",
	    		"NOPAT Margin",
	    		"Tax Rate",
	    		"Net Income",
	    		"Income Over Revenue",
	    		"Operating Cash Flow Over Income"

	    	];

	    var itemNumbersOM = [fr.OM.revenue,
	    		fr.OM.salesGrowth,
	    		fr.OM.COGS,
	    		fr.OM.operatingIncome,
	    		fr.OM.grossMargin,
	    		fr.OM.SGA,
	    		fr.OM.SGAOverSales,
	    		fr.OM.NOPAT,
	    		fr.OM.NOPATGrowth,
	    		fr.OM.NOPATMargin,
	    		fr.OM.taxRate,
	    		fr.OM.netIncome,
	    		fr.OM.incomeOverRevenue,
	    		fr.OM.operatingCashFlowOverIncome
	    	];

	    var body = <HTMLElement>document.createElement("tbody");
	    body.setAttribute("id", "tbodyOM");
	    var dummy = <HTMLElement>document.getElementById("tableOM");
	    dummy.appendChild(body);
	    var lastyear: string = lastYear(fr.OM.revenue);
		
		var col_1OM = <HTMLElement>document.getElementById("col_1OM");
		col_1OM.innerHTML = String(lastyear);
		var col_2OM = <HTMLElement>document.getElementById("col_2OM");
		col_2OM.innerHTML = String(parseInt(lastyear)- 1);
		var col_3OM = <HTMLElement>document.getElementById("col_3OM");
		col_3OM.innerHTML = String(parseInt(lastyear) -2);
		var col_4OM = <HTMLElement>document.getElementById("col_4OM");
		col_4OM.innerHTML = String(parseInt(lastyear) -3);
		var col_5OM = <HTMLElement>document.getElementById("col_5OM");
		col_5OM.innerHTML = String(parseInt(lastyear) -4);

		//  itemNumbersOM

	    for (var j=0; j<itemNumbersOM.length; j++) {
	    	
	    	var th = document.createElement("th").appendChild(document.createTextNode(itemNameOM[j])); 
		    var td = document.createElement("td");
		    var tr = document.createElement("tr");
		    td.appendChild(th);
		    tr.appendChild(td);

		    
		    

		    for (var i=0; i<5;i++) {

		    	var convertido: string;
		    	var itemPaint2: any = itemNumbersOM[j];
		  		console.log("itemPaint2");
		  		console.log(itemPaint2);
		  		var itemPaint = itemPaint2[parseInt(lastyear) - i];

		  		if ((typeof itemPaint) == 'number') {

		  			convertido = itemPaint.toLocaleString(undefined,{style: 'percent', minimumFractionDigits:1});
		  			
		  			if (itemNameOM[j] == "Operating Income") { convertido = numeral(itemPaint).format('0,0'); } 
		  			if (itemNameOM[j] == "NOPAT") { convertido = numeral(itemPaint).format('0,0'); }

			    	
			    } else {
			    	convertido = numeral(Number(itemPaint)).format('0,0');
			    }

			    if (itemPaint > 1000000) { convertido = "Inf"; }

		    	var thi = document.createElement("th").appendChild(document.createTextNode(convertido));
		    	var tdi = document.createElement("td");
		    	tdi.id = itemNameOM[j] + i;
		    	tdi.appendChild(thi);
				tr.appendChild(tdi);
				
		    }
		    var dummy3 = <HTMLElement>document.getElementById("tbodyOM");
		    dummy3.appendChild(tr);
	    }


	    /**

			MOBILE Operating Management

  		*/


	    for (var j=0; j<itemNumbersOM.length; j++) {

	    	// Creating table and the header
	    	var id = <HTMLElement>document.createElement("id");
	    	id.setAttribute("class", "table-responsive table-striped w-75 mx-auto");
	    	
	    	var table = <HTMLElement>document.createElement("table");
	    	table.setAttribute("class", "table table-hover table-striped w-75 mx-auto");
	    	
	    	var thead = <HTMLElement>document.createElement("thead");
	    	thead.setAttribute("class", "thead-dark");
	    	var tr = document.createElement("tr");
	    	tr.setAttribute("scope", "col");
	    	var itenName = document.createElement("th").appendChild(document.createTextNode(itemNameOM[j])); 
	    	var tHeader = <HTMLTableHeaderCellElement>document.createElement("th");
	    	tHeader.setAttribute("scope", "col");
	    	tHeader.appendChild(itenName);
	    	var noChar = document.createElement("th").appendChild(document.createTextNode("          ")); 
	    	var tHeader2 = <HTMLTableHeaderCellElement>document.createElement("th");
	    	tHeader2.setAttribute("scope", "col");
	    	tHeader2.appendChild(noChar);

	    	tr.appendChild(tHeader);
	    	tr.appendChild(tHeader2);
	    	thead.appendChild(tr);
	    	table.appendChild(thead);
	    	id.appendChild(table);


	    	// Creating rows of the table per year

	    	var tbody = <HTMLElement>document.createElement("tbody");	    

		    for (var i=0; i<5;i++) {

		    	var convertido: string;
		  		var itemPaint2: any = itemNumbersOM[j];
		  		var itemPaint = itemPaint2[parseInt(lastyear) - i];

		  		if ((typeof itemPaint) == 'number') {

		  			convertido = itemPaint.toLocaleString(undefined,{style: 'percent', minimumFractionDigits:1});
		  			if (itemNameOM[j] == "Operating Income") { convertido = numeral(itemPaint).format('0,0'); } 
		  			if (itemNameOM[j] == "NOPAT") { convertido = numeral(itemPaint).format('0,0'); }
		  			
			    } else {
			    	convertido = numeral(Number(itemPaint)).format('0,0');
			    }

			    if (itemPaint > 1000000) { convertido = "Inf"; }

			    var yeari = parseInt(lastyear) - i;
			    var yeariS = yeari.toString();
			    var year = document.createElement("td").appendChild(document.createTextNode(yeariS));
			    var tdYear = document.createElement("td");
			    tdYear.appendChild(year);

		    	var value = document.createElement("td").appendChild(document.createTextNode(convertido));
		    	var tdValue = document.createElement("td");
			    tdValue.appendChild(value);

		    	var tr = document.createElement("tr");
		    	tr.appendChild(tdYear);
		    	tr.appendChild(tdValue);
				tbody.appendChild(tr);
				
				
				
		    }
		    
		    table.appendChild(tbody);
		    var dummy10 = <HTMLElement>document.getElementById("OMM");
		    dummy10.appendChild(table);

		}

	    /***********************
	
			Investment Management

    	*/

	    var itemNameIM = ["Accounts Receivable Growth",
	    		"Inventory Growth",
	    		"Accounts Receivable Over Sales",
	    		"Inventory Over Sales",
	    		"Days Receivables",
	    		"Days Inventory",
	    		"Days Payables",
	    		"Sales Over Working Capital",
	    		"Sales Over Net LTAssets",
	    		"Sales Over Net Assets",
	    		"Cash Over NetAssets",
	    		"Cash Over Receivables",
	    	];

	    var itemNumbersIM = [fr.IM.accountsReceivableGrowth,
	    		fr.IM.inventoryGrowth,
	    		fr.IM.accountsReceivableOverSales,
	    		fr.IM.inventorySales,
	    		fr.IM.daysReceivables,
	    		fr.IM.daysInventory,
	    		fr.IM.daysPayables,
	    		fr.IM.salesOverWorkingCapital,
	    		fr.IM.salesOverNetLTAssets,
	    		fr.IM.salesOverNetAssets,
	    		fr.IM.cashOverNetAssets,
	    		fr.IM.cashOverReceivables,
	    	];

	    var bodyIM = <HTMLElement>document.createElement("tbody");
	    bodyIM.setAttribute("id", "tbodyIM");
	    var dummy2 = <HTMLElement>document.getElementById("tableIM");
	    dummy2.appendChild(bodyIM);
	    var lastyear: string = lastYear(fr.IM.cashAndMarketableSecurities);
		
		var col_1IM = <HTMLElement>document.getElementById("col_1IM");
		col_1IM.innerHTML = String(lastyear);
		var col_2IM = <HTMLElement>document.getElementById("col_2IM");
		col_2IM.innerHTML = String(parseInt(lastyear) -1);
		var col_3IM = <HTMLElement>document.getElementById("col_3IM");
		col_3IM.innerHTML = String(parseInt(lastyear) -2);
		var col_4IM = <HTMLElement>document.getElementById("col_4IM");
		col_4IM.innerHTML = String(parseInt(lastyear) -3);
		var col_5IM = <HTMLElement>document.getElementById("col_5IM");
		col_5IM.innerHTML = String(parseInt(lastyear) -4);


	    for (var j=0; j<itemNumbersIM.length; j++) {
	    	
	    	var th = document.createElement("th").appendChild(document.createTextNode(itemNameIM[j])); 
		    var td = document.createElement("td");
		    var tr = document.createElement("tr");
		    td.appendChild(th);
		    tr.appendChild(td);

		    
		    

		    for (var i=0; i<5;i++) {
		    	var convertido: string;
		  		var itemPaint2: any = itemNumbersIM[j];
		  		itemPaint = itemPaint2[parseInt(lastyear) - i];

		  		if ((typeof itemPaint) == 'number') {
		  			
		  			convertido = itemPaint.toLocaleString(undefined,{style: 'percent', minimumFractionDigits:1});
		  			
		  			if (itemNameIM[j] == "Days Receivables") { convertido = numeral(itemPaint).format('0,0'); } 
		  			if (itemNameIM[j] == "Days Inventory") { convertido = numeral(itemPaint).format('0,0'); }
		  			if (itemNameIM[j] == "Days Payables") { convertido = numeral(itemPaint).format('0,0'); }
		  			if (itemNameIM[j] == "Sales Over Working Capital") { convertido = numeral(itemPaint).format('0,0.00'); }
		  			if (itemNameIM[j] == "Sales Over Net LTAssets") { convertido = numeral(itemPaint).format('0,0.00'); }
		  			if (itemNameIM[j] == "Sales Over Net Assets") { convertido = numeral(itemPaint).format('0,0.00'); }

			    		
			    	
			    } else {
			    	convertido = numeral(Number(itemPaint)).format('0,0');
			    }

			    if (itemPaint > 1000000) { convertido = "Inf"; }
			    

		    	var thi = document.createElement("th").appendChild(document.createTextNode(convertido));
		    	var tdi = document.createElement("td");
		    	tdi.id = itemNameIM[j] + i;
		    	tdi.appendChild(thi);
				tr.appendChild(tdi);
				
		    }
		    var dummy4 = <HTMLElement>document.getElementById("tbodyIM");
		    dummy4.appendChild(tr);
	    }


	    /**

			MOBILE Investment Management

  		*/


	    for (var j=0; j<itemNumbersIM.length; j++) {

	    	// Creating table and the header
	    	var id = <HTMLElement>document.createElement("id");
	    	id.setAttribute("class", "table-responsive table-striped w-75 mx-auto");
	    	
	    	var table = <HTMLElement>document.createElement("table");
	    	table.setAttribute("class", "table table-hover table-striped w-75 mx-auto");
	    	
	    	var thead = <HTMLElement>document.createElement("thead");
	    	thead.setAttribute("class", "thead-dark");
	    	var tr = document.createElement("tr");
	    	tr.setAttribute("scope", "col");
	    	var itenName = document.createElement("th").appendChild(document.createTextNode(itemNameIM[j])); 
	    	var tHeader = <HTMLTableHeaderCellElement>document.createElement("th");
	    	tHeader.setAttribute("scope", "col");
	    	tHeader.appendChild(itenName);
	    	var noChar = document.createElement("th").appendChild(document.createTextNode("          ")); 
	    	var tHeader2 = <HTMLTableHeaderCellElement>document.createElement("th");
	    	tHeader2.setAttribute("scope", "col");
	    	tHeader2.appendChild(noChar);

	    	tr.appendChild(tHeader);
	    	tr.appendChild(tHeader2);
	    	thead.appendChild(tr);
	    	table.appendChild(thead);
	    	id.appendChild(table);


	    	// Creating rows of the table per year

	    	var tbody = <HTMLElement>document.createElement("tbody");	    

		    for (var i=0; i<5;i++) {

		    	var convertido: string;
		  		var itemPaint2: any = itemNumbersIM[j];
		  		var itemPaint = itemPaint2[parseInt(lastyear) - i];

		  		if ((typeof itemPaint) == 'number') {

		  			convertido = itemPaint.toLocaleString(undefined,{style: 'percent', minimumFractionDigits:1});
		  			if (itemNameIM[j] == "Days Receivables") { convertido = numeral(itemPaint).format('0,0'); } 
		  			if (itemNameIM[j] == "Days Inventory") { convertido = numeral(itemPaint).format('0,0'); }
		  			if (itemNameIM[j] == "Days Payables") { convertido = numeral(itemPaint).format('0,0'); }
		  			if (itemNameIM[j] == "Sales Over Working Capital") { convertido = numeral(itemPaint).format('0,0.00'); }
		  			if (itemNameIM[j] == "Sales Over Net LTAssets") { convertido = numeral(itemPaint).format('0,0.00'); }
		  			if (itemNameIM[j] == "Sales Over Net Assets") { convertido = numeral(itemPaint).format('0,0.00'); }
		  			
			    } else {
			    	convertido = numeral(Number(itemPaint)).format('0,0');
			    }

			    if (itemPaint > 1000000) { convertido = "Inf"; }

			    var yeari = parseInt(lastyear) - i;
			    var yeariS = yeari.toString();
			    var year = document.createElement("td").appendChild(document.createTextNode(yeariS));
			    var tdYear = document.createElement("td");
			    tdYear.appendChild(year);

		    	var value = document.createElement("td").appendChild(document.createTextNode(convertido));
		    	var tdValue = document.createElement("td");
			    tdValue.appendChild(value);

		    	var tr = document.createElement("tr");
		    	tr.appendChild(tdYear);
		    	tr.appendChild(tdValue);
				tbody.appendChild(tr);
				
				
				
		    }
		    
		    table.appendChild(tbody);
		    var dummy10 = <HTMLElement>document.getElementById("IMM");
		    dummy10.appendChild(table);

		}



	    /***********************
	
			Financial Management

    	*/

	    var itemNameFM = ["Debt To Capital Ratio",
	    		
	    		"Spread",
	    		"Net Financial Leverage (NFL)",
	    		"Financial Leverage (Spread X NFL)",
	    		"Current Ratio (CA / CL)",
	    		"Quick Ratio (AR + C / CL)",
	    		"Cash Ratio (C / CL)",
	    		"Operating Income vs Interest Expense",
	    		"Operating Cash Flow vs Interest expense",
	    	];

	    var itemNumbersFM = [fr.FM.debtToCapitalRatio,
	    		
	    		fr.FM.spread,
	    		fr.FM.netFinancialLeverage,
	    		fr.FM.financialLeverage,
	    		fr.FM.currentRatio,
	    		fr.FM.quickRatio,
	    		fr.FM.cashRatio,
	    		fr.FM.interestCoverage_operatingIncomeVSinterestexpense,
	    		fr.FM.interestCoverage_operatingCashFlowVSinterestexpense,
	    		
	    	];

	    var bodyFM = <HTMLElement>document.createElement("tbody");
	    bodyFM.setAttribute("id", "tbodyFM");
	    var dummy5 = <HTMLElement>document.getElementById("tableFM");
	    dummy5.appendChild(bodyFM);
	    var lastyear = lastYear(fr.FM.equity);
		
		var col_1FM = <HTMLElement>document.getElementById("col_1FM");
		col_1FM.innerHTML = String(lastyear);
		var col_2FM = <HTMLElement>document.getElementById("col_2FM");
		col_2FM.innerHTML = String(parseInt(lastyear) -1);
		var col_3FM = <HTMLElement>document.getElementById("col_3FM");
		col_3FM.innerHTML = String(parseInt(lastyear) -2);
		var col_4FM = <HTMLElement>document.getElementById("col_4FM");
		col_4FM.innerHTML = String(parseInt(lastyear) -3);
		var col_5FM = <HTMLElement>document.getElementById("col_5FM");
		col_5FM.innerHTML = String(parseInt(lastyear) -4);


	    for (var j=0; j<itemNumbersFM.length; j++) {
	    	
	    	var th = document.createElement("th").appendChild(document.createTextNode(itemNameFM[j])); 
		    var td = document.createElement("td");
		    var tr = document.createElement("tr");
		    td.appendChild(th);
		    tr.appendChild(td);

		    
		    

		    for (var i=0; i<5;i++) {
		    	var convertido: string;
		  		var itemPaint2: any = itemNumbersFM[j];
		  		itemPaint = itemPaint2[parseInt(lastyear) - i];
		  		if ((typeof itemPaint) == 'number') {

		  			convertido = itemPaint.toLocaleString(undefined,{style: 'percent', minimumFractionDigits:1});
		  			
		  			if (itemNameFM[j] == "Current Ratio (CA / CL)") { convertido = numeral(itemPaint).format('0,0.00'); } 
		  			if (itemNameFM[j] == "Quick Ratio (AR + C / CL)") { convertido = numeral(itemPaint).format('0,0.00'); }
		  			if (itemNameFM[j] == "Cash Ratio (C / CL)") { convertido = numeral(itemPaint).format('0,0.00'); }
		  			if (itemNameFM[j] == "Operating Income vs Interest Expense") { convertido = numeral(itemPaint).format('0,0.00'); }
		  			if (itemNameFM[j] == "Operating Cash Flow vs Interest expense") { convertido = numeral(itemPaint).format('0,0.00'); }

			    	
			    } else {
			    	convertido = numeral(Number(itemPaint)).format('0,0');
			    }

			    if (itemPaint > 1000000) { convertido = "Inf"; }

		    	var thi = document.createElement("th").appendChild(document.createTextNode(convertido));
		    	var tdi = document.createElement("td");
		    	tdi.id = itemNameFM[j] + i;
		    	tdi.appendChild(thi);
				tr.appendChild(tdi);
				
		    }
		    var dummy6 = <HTMLElement>document.getElementById("tbodyFM");
		    dummy6.appendChild(tr);
	    }
	    
	    

	     /**

			MOBILE Financial Management

  		*/


	    for (var j=0; j<itemNumbersFM.length; j++) {

	    	// Creating table and the header
	    	var id = <HTMLElement>document.createElement("id");
	    	id.setAttribute("class", "table-responsive table-striped w-75 mx-auto");
	    	
	    	var table = <HTMLElement>document.createElement("table");
	    	table.setAttribute("class", "table table-hover table-striped w-75 mx-auto");
	    	
	    	var thead = <HTMLElement>document.createElement("thead");
	    	thead.setAttribute("class", "thead-dark");
	    	var tr = document.createElement("tr");
	    	tr.setAttribute("scope", "col");
	    	var itenName = document.createElement("th").appendChild(document.createTextNode(itemNameFM[j])); 
	    	var tHeader = <HTMLTableHeaderCellElement>document.createElement("th");
	    	tHeader.setAttribute("scope", "col");
	    	tHeader.appendChild(itenName);
	    	var noChar = document.createElement("th").appendChild(document.createTextNode("          ")); 
	    	var tHeader2 = <HTMLTableHeaderCellElement>document.createElement("th");
	    	tHeader2.setAttribute("scope", "col");
	    	tHeader2.appendChild(noChar);

	    	tr.appendChild(tHeader);
	    	tr.appendChild(tHeader2);
	    	thead.appendChild(tr);
	    	table.appendChild(thead);
	    	id.appendChild(table);


	    	// Creating rows of the table per year

	    	var tbody = <HTMLElement>document.createElement("tbody");	    

		    for (var i=0; i<5;i++) {

		    	var convertido: string;
		  		var itemPaint2: any = itemNumbersFM[j];
		  		var itemPaint = itemPaint2[parseInt(lastyear) - i];

		  		if ((typeof itemPaint) == 'number') {

		  			convertido = itemPaint.toLocaleString(undefined,{style: 'percent', minimumFractionDigits:1});
		  			if (itemNameFM[j] == "Current Ratio") { convertido = numeral(itemPaint).format('0,0.00'); } 
		  			if (itemNameFM[j] == "Quick Ratio") { convertido = numeral(itemPaint).format('0,0.00'); }
		  			if (itemNameFM[j] == "Cash Ratio") { convertido = numeral(itemPaint).format('0,0.00'); }
		  			if (itemNameFM[j] == "Operating Income vs Interest Expense") { convertido = numeral(itemPaint).format('0,0.00'); }
		  			if (itemNameFM[j] == "Operating Cash Flow vs Interest expense") { convertido = numeral(itemPaint).format('0,0.00'); }

		  			
			    } else {
			    	convertido = numeral(Number(itemPaint)).format('0,0');
			    }


			    if (itemPaint > 1000000) { convertido = "Inf"; }

			    var yeari = parseInt(lastyear) - i;
			    var yeariS = yeari.toString();
			    var year = document.createElement("td").appendChild(document.createTextNode(yeariS));
			    var tdYear = document.createElement("td");
			    tdYear.appendChild(year);

		    	var value = document.createElement("td").appendChild(document.createTextNode(convertido));
		    	var tdValue = document.createElement("td");
			    tdValue.appendChild(value);

		    	var tr = document.createElement("tr");
		    	tr.appendChild(tdYear);
		    	tr.appendChild(tdValue);
				tbody.appendChild(tr);
				
				
				
		    }
		    
		    table.appendChild(tbody);
		    var dummy10 = <HTMLElement>document.getElementById("FMM");
		    dummy10.appendChild(table);

		}









	    




	    
	    
		

		
				

    });
  });
});



function paint() {
	console.log("XXXXXXX");
}





